import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import billingInfo from "../manage/billing-info.vue";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import saleRefSearch from "./saleRefSearch.vue";

export default {
  data: () => ({
    salesList: [],
    error: "",
    searchText: "",
    showLoader: false,
    salesRefSearch: false,
    ClearSearch: false,
    teamList: [],
    usersList: [],
    opened: [],
    number: 0,
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    selectedSearchInput: {
      agent: true,
      sale_id: true,
      quotation_id: true,
      lead_id: true,
      sale_reference_id: true,
      client_name: true,
      passenger: true,
      pnr: true,
      acn: true,
      from: true,
      to: true,
    },
    recordTakeOptions: [25, 50, 100, 200],
    expandedRow: false,
  }),
  mixins: [paginationMixin],
  components: {
    "header-list": headerList,
    "billing-info": billingInfo,
    PaginationComponent,
    saleRefSearch,
  },
  methods: {
    openSaleRefSearch() {
      let _vm = this;
      _vm.salesRefSearch = true;
    },
    handleSearch(selectedData) {
      let _vm = this;
      _vm.getAll(selectedData);
      _vm.salesRefSearch = false;
      _vm.ClearSearch = true;
    },

    Clear() {
      let _vm = this;
      _vm.lastQueryString = "";
      _vm.all_sales = localStorage.getItem("all_sales") === "true" || false;
      _vm.getAll();
      _vm.ClearSearch = false;
    },
    // formatDateTime(timestamp) {
    //   const date = new Date(timestamp);
    //   const months = [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ];

    //   const month = months[date.getMonth()];
    //   const day = date.getDate().toString().padStart(2, "0");
    //   const year = date.getFullYear().toString().slice(2);
    //   const time = timestamp.slice(11, 16);

    //   return `${month}/${day}/${year} ${time}`;
    // },
    calculateTotalOpenSumOfSellingPrices(sale) {
      let totalSum = 0;
      sale.sales.forEach((item) => {
        if (
          item.financial_informations &&
          item.financial_informations.financial_pax
        ) {
          totalSum += parseFloat(
            this.calculateTotalSellingPrice(
              item.financial_informations.financial_pax
            ).toFixed(2)
          );
        }
      });
      return totalSum.toFixed(2);
    },
    calculateTotalSellingPrice(financialPax) {
      if (!financialPax || !Array.isArray(financialPax)) return 0;

      let totalSellingPrice = 0;
      financialPax.forEach((pax) => {
        if (pax.selling_price) {
          totalSellingPrice += parseFloat(pax.selling_price);
        }
      });
      return totalSellingPrice;
    },
    // getNextPage(page) {
    //   this.$refs.paginate.selected = page;
    //   this.paginate.current_page = page;
    //   this.getAll();
    // },
    // start sales details
    toggleOpen(k) {
      this.expandedRow = this.expandedRow === k ? null : k;
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [id];
      }
    },
    increment() {
      this.number++;
    },
    decrement() {
      this.number--;
    },
    // end sales details
    search() {
      this.getAll();
    },
    getQueryString(selectedData) {
      // Create query string with selected data
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&" +
        "status=" +
        this.$route.params.status +
        "&take=" +
        this.paginate.per_page;
      // Append selected data to the query string if available
      queryString += selectedData.agent_id
        ? "&agent=" + selectedData.agent_id
        : "";
      queryString += selectedData.section_id
        ? "&sale_id=" + selectedData.section_id
        : "";
      queryString += selectedData.Lead ? "&lead_id=" + selectedData.Lead : "";
      queryString += selectedData.quotation_id
        ? "&quotation_id=" + selectedData.quotation_id
        : "";
      queryString += selectedData.sale_reference_id
        ? "&sale_reference_id=" + selectedData.sale_reference_id
        : "";
      queryString += selectedData.Client_name
        ? "&client_name=" + selectedData.Client_name
        : "";
      queryString += selectedData.Passenger_name
        ? "&passenger_name=" + selectedData.Passenger_name
        : "";
      queryString += selectedData.pnr ? "&pnr=" + selectedData.pnr : "";
      queryString += selectedData.ACN ? "&acn=" + selectedData.ACN : "";
      queryString += selectedData.saleDateFrom
        ? "&from=" + selectedData.saleDateFrom
        : "";
      queryString += selectedData.saleDateTo
        ? "&to=" + selectedData.saleDateTo
        : "";
      return queryString;
    },
    getTeam() {
      const _vm = this;
      this.axios
        .get("/team-details", _vm.teamList)
        .then(function (response) {
          _vm.teamList = response.data.data;
        })
        .catch(function () {});
    },
    getUserList() {
      let _vm = this;
      this.axios
        .get("/user-list")
        .then(function (response) {
          _vm.usersList = response.data.data;
        })
        .catch(function () {});
    },
    getAll(selectedData = {}) {
      let _vm = this;
      if (Object.keys(selectedData).length === 0 && this.lastQueryString) {
        selectedData = this.lastQueryString;
      } else {
        this.lastQueryString = selectedData;
      }
      let queryString = this.getQueryString(selectedData);
      _vm.showLoader = true;

      this.axios
        .get("/sales-by-status" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.salesList = response.data.data.data;

          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function (error) {});
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
  },
  mounted() {
    this.getAll();
    this.getTeam();
    this.getUserList();
  },
};
